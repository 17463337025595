// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-entrance-index-tsx": () => import("./../../../src/pages/entrance/index.tsx" /* webpackChunkName: "component---src-pages-entrance-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nursery-index-tsx": () => import("./../../../src/pages/nursery/index.tsx" /* webpackChunkName: "component---src-pages-nursery-index-tsx" */),
  "component---src-pages-program-index-tsx": () => import("./../../../src/pages/program/index.tsx" /* webpackChunkName: "component---src-pages-program-index-tsx" */)
}

